import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { CheckCircleIcon } from "@heroicons/react/solid"
import {
  GlobeIcon,
  AdjustmentsIcon,
  ChipIcon,
  MinusCircleIcon,
} from "@heroicons/react/outline"
import CTA from "../components/cta"

export const query = graphql`
  query KongsbergWhyQuery {
    strapi {
      why: kongsbergWhy {
        title
        preTitle
        subTitle
        keySellingPoints {
          title
          description
        }
        headingTitle
        headingSubTitle
        headingText
        comparisons {
          feature
          kongsberg
          others
          zund
        }
        comparisonDisclaimer
      }
    }
  }
`

const WhyPage = ({
  data: {
    strapi: { why },
  },
}) => {
  return (
    <Layout>
      <SEO title={why.title} />

      <div className="bg-brand-100 relative">
        <div className="absolute z-0 top-0 square-float flex justify-center w-full h-full overflow-hidden">
          <div className="absolute square bg-brand-200" />
          <div className="absolute square bg-brand-50" />
        </div>

        <div className="container relative mx-auto z-10">
          <div className="relative z-10 pt-48 pb-32 text-center">
            <div className="heading-sm mb-0">{why.preTitle}</div>
            <h1 className="max-w-4xl mx-auto heading-xl">{why.title}</h1>
            <p
              className="max-w-4xl mx-auto text-2xl"
              dangerouslySetInnerHTML={{
                __html: why.subTitle.replace(/\n/g, "<br />"),
              }}
            />
            <div className="mt-20 grid grid-cols-1 lg:grid-cols-3 gap-4">
              {why.keySellingPoints.map((ksp, i) => (
                <div key={i}>
                  <div className="mx-auto mb-4 w-16 h-16 rounded-full bg-white flex items-center justify-center">
                    {i === 0 ? (
                      <GlobeIcon className="w-12 h-12 text-brand-500" />
                    ) : i === 1 ? (
                      <AdjustmentsIcon className="w-12 h-12 text-brand-500" />
                    ) : (
                      <ChipIcon className="w-12 h-12 text-brand-500" />
                    )}
                  </div>
                  <h3 className="heading-sm">{ksp.title}</h3>
                  <p>{ksp.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto grid grid-cols-12 mt-32 overflow-x-auto">
        <div className="col-span-12 lg:col-span-8 lg:col-start-3 text-center">
          <div className="block mx-auto w-16 bg-brand-500 h-1.5 text-center mb-3" />
          <h2 className="heading-lg">{why.headingTitle || ""}</h2>
          <p className="text-lg md:text-xl">{why.headingSubTitle || ""}</p>
        </div>
        <div className="mt-10 col-span-12 lg:col-span-10 lg:col-start-2 text-center">
          <div
            className="md:columns-2 text-lg text-left"
            dangerouslySetInnerHTML={{
              __html: why.headingText.replace(/\n/g, "<br />"),
            }}
          />
        </div>
      </div>

      {why.comparisons && why.comparisons.length > 0 && (
        <>
          <div className="overflow-x-auto">
            <div className="container mx-auto grid grid-cols-12 min-w-max">
              <div className="mt-16 col-span-12 lg:col-span-10 lg:col-start-2 text-center p-2 lr-6">
                <div className="grid grid-cols-2 gap-8">
                  <div className="col-start-2 grid grid-cols-3 text-center px-6">
                    <h3 className="heading-sm text-base lg:text-xl mb-0">
                      Kongsberg
                    </h3>
                    <h3 className="heading-sm text-base lg:text-xl mb-0">
                      Zünd
                    </h3>
                    <h3 className="heading-sm text-base lg:text-xl mb-0">
                      Andre
                    </h3>
                  </div>
                </div>
              </div>
              <div className="mt-2 col-span-12 lg:col-span-10 lg:col-start-2 text-center bg-brand-500 text-white rounded-xl p-2 pl-0">
                <div className="grid grid-cols-2 gap-8 h-6">
                  <div />
                  <div className="rounded-t-lg bg-white" />
                </div>
                {why.comparisons.map((comp, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-2 gap-8 group relative"
                  >
                    <div className="absolute z-10 top-0 left-0 right-0 bottom-0 group-hover:shadow-lg" />
                    <div className="h-20 flex items-center pl-8">
                      <p
                        className={`h-full flex items-center w-full text-left border-brand-400 ${
                          i === why.comparisons.length - 1 ? "" : "border-b"
                        } group-hover:border-transparent`}
                      >
                        {comp.feature || ""}
                      </p>
                    </div>

                    <div className="grid grid-cols-3 bg-white items-center text-center gap-0 px-6">
                      <div
                        className={`h-full text-brand-500 flex items-center border-brand-200 border-r ${
                          i === why.comparisons.length - 1 ? "" : "border-b"
                        } group-hover:border-b-0`}
                      >
                        {comp.kongsberg ? (
                          <CheckCircleIcon className="w-8 h-8 mx-auto" />
                        ) : (
                          <MinusCircleIcon className="w-8 h-8 mx-auto" />
                        )}
                      </div>
                      <div
                        className={`h-full text-brand-500 flex items-center border-brand-200 border-r ${
                          i === why.comparisons.length - 1 ? "" : "border-b"
                        } group-hover:border-b-0`}
                      >
                        {comp.zund ? (
                          <CheckCircleIcon className="w-8 h-8 mx-auto" />
                        ) : (
                          <MinusCircleIcon className="w-8 h-8 mx-auto" />
                        )}
                      </div>
                      <div
                        className={`h-full text-brand-500 flex items-center border-brand-200 ${
                          i === why.comparisons.length - 1 ? "" : "border-b"
                        } group-hover:border-b-0`}
                      >
                        {comp.others ? (
                          <CheckCircleIcon className="w-8 h-8 mx-auto" />
                        ) : (
                          <MinusCircleIcon className="w-8 h-8 mx-auto" />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="grid grid-cols-2 gap-8 h-6">
                  <div />
                  <div className="rounded-b-lg bg-white" />
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto grid grid-cols-12 mb-32">
            <p className="text-sm mt-4 text-gray-700 col-span-12 lg:col-span-10 lg:col-start-2">
              {why.comparisonDisclaimer}
            </p>
          </div>
        </>
      )}

      <CTA />
    </Layout>
  )
}

export default WhyPage
